<template>
    <!--Content start-->
    <main id="content">
      <div class="container">
        <div class="row">
            <div class="col-12 col-xl-12"  style="padding-left: - var(--bs-gutter-x, 1.5rem);padding-right: - var(--bs-gutter-x, 1.5rem);">
                <div class="row">
                    <div class="col-12" style="padding-left: 0;padding-right: 0;">
                        <div class="plyr">
                      <iframe v-bind:src="url_data" title="Byte-Include" frameborder="0" allowfullscreen scrolling="no"></iframe> 
                        </div>
                    </div>
                </div>
            </div>
          <!-- start left column -->
          <div class="col-md-8">
            <!-- block start -->
            <div class="block-area">
              <!-- block title -->
              <div class="block-title-13">
                <h4 class="h5 title-box-dot ">
                  <span>اقتراحات من انثوغرافيك</span>
                </h4>
                <div class="dot-line"></div>
              </div>
              <div class="gap-0"></div>
              <!-- block content -->
              <div class="border-bottom-last-0 first-pt-0">
                <!--post start-->
                <article class="card card-full hover-a py-4" v-for="publish in video.slice(0,8)" :key="publish.id">
                  <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6">
                      <!--thumbnail-->
                      <div class="ratio_360-202 image-wrapper">
                        <a @click="getFeed(publish)" class="pointer">
                          <img class="img-fluid pointer" v-lazy="publish.poster" :alt="publish.title_ar">
                          <div class="post-type-icon hover-hide">
                            <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6">
                      <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
                        <!--title-->
                        <h3 class="card-title h2 h3-sm h2-md  pointer">
                          <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                        </h3>
                        <!--author and date-->
                        <div class="card-text mb-2 text-muted small">
                          <span class="d-none d-sm-inline me-1">
                            <a class="fw-bold " href="">تاق برس</a>
                          </span>
                          <time datetime="2019-10-21 ">{{ publish.upload_date.substring(0,10) }}</time>
                        </div>
                        <!--description-->
                        <p class="card-text ">{{ publish.desc_ar.substring(0,120)+".." }}</p>
                      </div>
                    </div>
                  </div>
                </article>
                <!--post start-->
              </div>
              <!-- end block content -->
            </div>
            <!--end block-->
          </div>
          <!-- end left column -->

          <!-- start right column -->
          <aside class="col-md-4 end-sidebar-lg">
            <div class="sticky">
              <aside class="widget">
                <div class="block-title-4">
                  <h4 class="h5 title-arrow ">
                    <span>مزيد من انثوغرافيك</span>
                  </h4>
                </div>
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(8,9)" :key="publish.id">
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" :alt="publish.title_ar">
                        <div class="post-type-icon">
                          <span class="fa-stack-sea text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="card-body">
                      <h2 class="card-title h1-sm h3-md  pointer">
                        <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                      </h2>
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold " >تاق برس</a>
                        </span>
                        <time datetime="2019-10-22 ">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <p class="card-text ">{{ publish.desc_ar.substring(0,120)+".." }}</p>
                    </div>
                  </article>
                </div>
                <div class="small-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(9,12)" :key="publish.id">
                    <div class="row">
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid pointer" v-lazy="publish.poster" :alt="publish.title_ar">
                            <div class="post-type-icon hover-show">
                              <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md  pointer">
                            <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-22 ">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="gap-0"></div>
              </aside>

              <aside class="widget">
                <div class="block-title-4">
                  <h4 class="h5 title-arrow">
                  </h4>
                </div>
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(12,13)" :key="publish.id">
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" :alt="publish.title_ar">
                        <div class="post-type-icon">
                          <span class="fa-stack-sea text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="card-body">
                      <h2 class="card-title h1-sm h3-md pointer">
                        <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                      </h2>
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold" >تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <p class="card-text">{{ publish.desc_ar.substring(0,120)+".." }}</p>
                    </div>
                  </article>
                </div>
                <div class="small-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(13,16)" :key="publish.id">
                    <div class="row">
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid pointer" v-lazy="publish.poster" data-src="" :alt="publish.title_ar">
                            <div class="post-type-icon hover-show">
                              <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md pointer">
                            <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="gap-0"></div>
              </aside>
              <aside class="widget">
                <div class="block-title-4">
                  <h4 class="h5 title-arrow">
                  </h4>
                </div>
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(16,17)" :key="publish.id">
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid pointer" v-lazy="publish.poster" :alt="publish.title_ar">
                        <div class="post-type-icon">
                          <span class="fa-stack-sea text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                              <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="card-body">
                      <h2 class="card-title h1-sm h3-md pointer">
                        <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                      </h2>
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold" >تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <p class="card-text">{{ publish.desc_ar.substring(0,120)+".." }}</p>
                    </div>
                  </article>
                </div>
                <div class="small-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in video.slice(17,20)" :key="publish.id">
                    <div class="row">
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid pointer" v-lazy="publish.poster" :alt="publish.title_ar">
                            <div class="post-type-icon hover-show">
                              <span class="fa-stack-sea text-primary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                              </svg>
                            </span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md pointer">
                            <a @click="getFeed(publish)">{{ publish.title_ar.substring(0,70)+".." }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-22">{{ publish.upload_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="gap-0"></div>
              </aside>
            </div>
          </aside>
        </div>
      </div>
    </main>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next'
import { onMounted } from '@vue/runtime-core';
import { useMeta } from 'vue-meta'
export default {
   name: 'Videos',
   props: {
       id: String,
    },
   async setup(props) {
         useMeta({ title: 'اخبار فيديو' })
         const router = useRouter();
         const route = useRoute();
         const cookie = useCookie()
         const content = ref([]);
         const video = ref([]);
         const url_data = ref([]);

        const getUrl = async () => {
            try {
                url_data.value = `https://clicktwoplay.com/VideoPage/video.php?ID=${props.id}`
            } catch (err) {
                console.log(err);
            }
        };

        onMounted(() => {
            getUrl();
        });

        try {
         await HTTP.get("getVideos.php?LIMIT=30&OFFSET=0").then((res) => {
            video.value = res.data.getVideos; 
          });
        } catch (err) {
          console.log(err);
        }



        const getFeed = (publish) => {
            router.push({ name: "Video", params: { id: publish.id } });
        };        
      return {
        content,   
        video,
        getFeed,
        url_data
      };
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.plyr {
  font-family: 'Cairo', sans-serif;
  font-weight: 400;
  border-radius: 16px;
  margin-top: 30px;
  height: 100%;
}
.comments__item {
  margin-bottom: 20px;
  display: block;
  background-color: #d1def736;
  padding: 10px;
  border-radius: 16px;
}
.comments__name {
  display: flex;
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  font-weight: 500;
  text-align: match-parent;
  margin-right: 75px;
}

iframe{
  width: 100%;
  height: 90vh;
}
@media(max-width:1024px){
  iframe{
    max-height: 53vh;
  }
}
@media(max-width:834px){
  iframe{
    max-height: 49vh;
  }
}
@media(max-width:820px){
  iframe{
    max-height: 50vh;
  }
}
@media(max-width:810px){
  iframe{
    max-height: 51vh;
  }
}
@media(max-width:786px){
  iframe{
    max-height: 50vh;
  }
}
@media(max-width:768px){
  iframe{
    max-height: 53vh;
  }
}
@media(max-width:428px){
  iframe{
    max-height: 37vh;
  }
}
@media(max-width:412px){
  iframe{
    max-height: 37vh;
  }
}
@media(max-width:414px){
  iframe{
    max-height: 40vh;
  }
}

@media(max-width:384px){
  iframe{
    max-height: 37vh;
  }
}
@media(max-width:375px){
  iframe{
    max-height: 40vh;
  }
}
@media(max-width:360px){
  iframe{
    max-height: 40vh;
  }
}
/* .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before, .video-js .vjs-control:focus {
  text-shadow: none;
} */

/* .vjs-control-bar a.vjs-control.vjs-cloudinary-button {
  opacity: 0 !important;
} */
</style>